import { makeAutoObservable } from "mobx";

class SuccessObserver {
    isSuccess = false;

    description = "";

    constructor() {
        makeAutoObservable(this);
    }

    setSuccessInfo() {
        this.isSuccess = true;
    }

    setSuccessInfoWithText(message: string) {
        this.isSuccess = true;
        this.description = message;
    }

    resetSuccessInfo() {
        this.isSuccess = false;
        this.description = "";
    }

    closeNotification() {
        this.isSuccess = false;
    }
}

export const successInfo = new SuccessObserver();
