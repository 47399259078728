import React, { useCallback, useContext, useEffect, useLayoutEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import SignUpForm from "@screens/Landing/Authorization/SignUp/Form";
import { SignUpInvitation } from "@screens/Landing/Authorization/SignUp/Invitation";
import { ForgotCredentials } from "@screens/Landing/Authorization/ForgotPassword";
import { ResetCredentials } from "@screens/Landing/Authorization/ResetPassword";
import { SuccessSentReset } from "@screens/Landing/Authorization/SuccessSentReset";
import { CodeForm } from "@screens/Landing/Authorization/SignIn/MFA/Code";
import PhoneForm from "@screens/Landing/Authorization/SignIn/MFA/Phone";
import { SuccessReset } from "@screens/Landing/Authorization/SuccessReset";
import { ResetError } from "@screens/Landing/Authorization/ResetError";
import { deleteAccount } from "@state/account";
import { getParameterByName } from "@common/getParameterByName";
import { RootState } from "@state/index";
import { acceptUserInvitationRequest, getUserInvitationRequest } from "@services/Invite";
import { EAuthorizationStatus, ECode } from "@interfaces";
import { getHashParameterByName } from "@common/getHashParameterByName";
import { Form, TInvite } from "@screens/Landing/Authorization/types";
import { resetActiveProject } from "@state/activeProject";
import { If } from "@components/If";
import { Pending } from "@screens/Panding";
import * as authorizationStyle from "@screens/Landing/Authorization/style.scss";
import { ExpiredPassword } from "@screens/Landing/Authorization/ExpiredPassword";
import { AuthContext } from "@common/auth-context";
import { VerifyEmail } from "@screens/Landing/Authorization/VerifyEmail";
import { requestCheckProvider } from "@services/Authorization";

import SignInForm from "./SignIn/Form";
import { MultiFactorResolver } from "@firebase/auth";
import { deleteTokenInStorage } from "@common/tokenStorage";
import MFA from "@screens/Landing/Authorization/SignIn/MFA/MFA";
import QRCode from "@screens/Landing/Authorization/SignIn/MFA/QRCode";

type StoreType = {
    typeOfForm: Form;
    resolver: MultiFactorResolver | null;
    email: string;
    actionCode: string;
    isPending: boolean;
    invitation: TInvite | null;
    isAccept: boolean;
};

const initState = {
    typeOfForm: Form.SignIn,
    resolver: null,
    email: "",
    actionCode: "",
    isPending: false,
    invitation: null,
    isAccept: false,
};

type ActionType =
    | {
          type: "setTypeOfForm";
          payload: Form;
      }
    | {
          type: "setResolver";
          payload: MultiFactorResolver;
      }
    | {
          type: "setEmail";
          payload: string;
      }
    | {
          type: "setActionCode";
          payload: string;
      }
    | {
          type: "setIsPending";
          payload: boolean;
      }
    | {
          type: "setInvitation";
          payload: TInvite;
      }
    | {
          type: "init";
          payload: StoreType;
      }
    | {
          type: "setIsAccept";
          payload: boolean;
      };

const reducer = (state: StoreType, action: ActionType): StoreType => {
    switch (action.type) {
        case "setTypeOfForm":
            return { ...state, typeOfForm: action.payload };
        case "init":
            return initState;
        case "setActionCode":
            return { ...state, actionCode: action.payload };
        case "setEmail":
            return { ...state, email: action.payload };
        case "setIsPending":
            return { ...state, isPending: action.payload };
        case "setInvitation":
            return { ...state, invitation: action.payload };
        case "setIsAccept":
            return { ...state, isAccept: action.payload };
        case "setResolver":
            return { ...state, resolver: action.payload };
        default:
            return initState;
    }
};

const Authorization = ({ initPage }: { initPage: Form }) => {
    const dispatch = useDispatch();
    const account = useSelector((state: RootState) => state.account);
    const navigate = useNavigate();
    const [store, dispatchLocal] = useReducer(reducer, initState);

    const [, setAuthorizationStatus] = useContext(AuthContext);
    useLayoutEffect(() => {
        deleteTokenInStorage();
        dispatchLocal({ type: "setTypeOfForm", payload: initPage });
        dispatchLocal({ type: "setActionCode", payload: getParameterByName(ECode.ActionCode) });
    }, [initPage]);

    const resetPassword = useCallback(async () => {
        const droppedEmail = await window.fb.default.auth().verifyPasswordResetCode(store.actionCode);
        dispatchLocal({ type: "setEmail", payload: droppedEmail });
        dispatch(deleteAccount());
        dispatchLocal({ payload: Form.ResetPwd, type: "setTypeOfForm" });
        dispatchLocal({ type: "setIsPending", payload: false });
    }, [store.actionCode, dispatch]);

    const revertSecondFactory = useCallback(() => {
        window.fb.default.auth().applyActionCode(store.actionCode);
        navigate("/");
        dispatchLocal({ type: "setActionCode", payload: "" });
    }, [store.actionCode, navigate]);

    const verifyEmail = useCallback(async () => {
        const { currentUser } = window.fb.default.auth();
        await window.fb.default.auth().applyActionCode(store.actionCode);
        currentUser?.reload();
        navigate("/");
        dispatchLocal({ type: "setActionCode", payload: "" });
        dispatchLocal({ type: "setTypeOfForm", payload: Form.SignIn });
    }, [store.actionCode, navigate]);

    const backToSignInButton = () => {
        dispatch(deleteAccount());
        navigate("/");
        dispatchLocal({ type: "init", payload: initState });
    };

    const acceptInvite = useCallback(
        async (token: string) => {
            const inviteCode = getHashParameterByName(ECode.Invite);
            if (!inviteCode || !store.invitation) return;

            try {
                setAuthorizationStatus(EAuthorizationStatus.Authorized);
                await acceptUserInvitationRequest(inviteCode, token);
                dispatchLocal({ type: "setActionCode", payload: "" });
                dispatchLocal({ type: "setIsAccept", payload: true });
            } catch {
                dispatchLocal({ type: "setTypeOfForm", payload: Form.ErrorLink });
            } finally {
                navigate("/");
                dispatchLocal({ type: "setIsPending", payload: false });
            }
        },
        [store.invitation, setAuthorizationStatus, navigate],
    );

    useEffect(() => {
        (async function checkInvite() {
            if (!store.invitation) return;
            dispatchLocal({ type: "setIsPending", payload: true });
            const { is_new, user_email, is_invited = true, project_id } = store.invitation;
            const { name = "" } = account;
            const lowerCaseUserEmail = user_email.toLowerCase().trim();

            if (store.isAccept) {
                dispatchLocal({ type: "setTypeOfForm", payload: Form.SignIn });
                return;
            }
            if (is_new && !name) {
                const inviteCode = getHashParameterByName(ECode.Invite);
                const {
                    data: { redirect_url },
                } = await requestCheckProvider(user_email, window.location.origin, inviteCode);

                if (redirect_url) {
                    window.location.replace(redirect_url);
                    return;
                }
                dispatchLocal({ type: "setTypeOfForm", payload: Form.UserInvitation });
                dispatchLocal({ type: "setIsPending", payload: false });
                dispatchLocal({ type: "setEmail", payload: user_email });
            } else if (name.toLowerCase() === lowerCaseUserEmail && is_invited) {
                navigate(`/projects/${project_id}/dashboard`);
            } else if (name.toLowerCase() === lowerCaseUserEmail && !is_new) {
                navigate(`/projects/${project_id}/dashboard`);
            } else if (name) {
                deleteTokenInStorage();
                dispatch(deleteAccount());
            } else if (!name) {
                setTimeout(() => {
                    dispatchLocal({ type: "setIsPending", payload: false });
                }, 3000);
            } else if (name.toLowerCase() === lowerCaseUserEmail && !is_invited) {
                setAuthorizationStatus(EAuthorizationStatus.Authorized);
            }
        })();
    }, [acceptInvite, account, dispatch, store.invitation, setAuthorizationStatus]);

    const acceptSignUp = async (token: string) => {
        const inviteCode = getHashParameterByName(ECode.Invite);
        if (inviteCode && !store.isAccept) {
            dispatchLocal({ type: "setIsPending", payload: true });
            await acceptInvite(token);
        }
    };

    useEffect(() => {
        const inviteCode = getHashParameterByName(ECode.Invite);
        if (inviteCode) {
            dispatchLocal({ type: "setIsPending", payload: true });
            getUserInvitationRequest(inviteCode)
                .then(async ({ data }) => {
                    dispatchLocal({ type: "setInvitation", payload: data.invitation });
                })
                .catch(() => {
                    dispatch(resetActiveProject());
                    dispatchLocal({ type: "setTypeOfForm", payload: Form.ErrorLink });
                    dispatchLocal({ type: "setIsPending", payload: false });
                    navigate("/");
                })
                .finally(() => dispatchLocal({ type: "setIsPending", payload: false }));
        }
    }, [dispatch, navigate, setAuthorizationStatus]);

    useLayoutEffect(() => {
        if (store.actionCode) {
            window.fb.default
                .auth()
                .checkActionCode(store.actionCode)
                .then(async ({ operation }: { operation: string }) => {
                    if (operation === "PASSWORD_RESET") {
                        await resetPassword();
                    }

                    if (operation === "VERIFY_EMAIL") {
                        await verifyEmail();
                    }

                    if (operation === "REVERT_SECOND_FACTOR_ADDITION") {
                        revertSecondFactory();
                    }
                })
                .catch(() => {
                    dispatchLocal({ type: "setActionCode", payload: "" });
                    navigate("/");
                    dispatchLocal({ type: "setTypeOfForm", payload: Form.ErrorLink });
                })
                .finally(() => {
                    dispatchLocal({ type: "setIsPending", payload: false });
                });
        }
    }, [store.actionCode, resetPassword, account.authorized, verifyEmail, revertSecondFactory, navigate]);

    const content = (type: Form) => {
        switch (type) {
            case Form.SignIn:
                return (
                    <SignInForm
                        goToMFA={(value: MultiFactorResolver, email) => {
                            dispatchLocal({ type: "setEmail", payload: email });
                            dispatchLocal({ type: "setResolver", payload: value });
                            dispatchLocal({ type: "setTypeOfForm", payload: Form.Code });
                        }}
                        goToForgotPwd={() => dispatchLocal({ type: "setTypeOfForm", payload: Form.ForgotPwd })}
                    />
                );
            case Form.ForgotPwd:
                return (
                    <>
                        <div className={authorizationStyle.backToSignInButton} onClick={backToSignInButton}>
                            ← Back to Sign In
                        </div>
                        <ForgotCredentials
                            setSuccess={(value: string) => {
                                dispatchLocal({ type: "setEmail", payload: value });
                                dispatchLocal({ type: "setTypeOfForm", payload: Form.SuccessSentPWD });
                            }}
                        />
                    </>
                );
            case Form.ResetPwd:
                return (
                    <>
                        <div className={authorizationStyle.backToSignInButton} onClick={backToSignInButton}>
                            ← Back to Sign In
                        </div>
                        <ResetCredentials
                            email={store.email}
                            actionCode={store.actionCode}
                            setSuccess={(value) => {
                                dispatchLocal({ type: "setActionCode", payload: "" });
                                navigate("/");
                                dispatchLocal({ type: "setEmail", payload: value });
                                dispatchLocal({ type: "setTypeOfForm", payload: Form.SuccessResetPWD });
                            }}
                            setError={() => dispatchLocal({ type: "setTypeOfForm", payload: Form.ErrorLink })}
                        />
                    </>
                );
            case Form.Code:
                return (
                    <>
                        <div className={authorizationStyle.backToSignInButton} onClick={backToSignInButton}>
                            ← Back to Sign In
                        </div>
                        <CodeForm resolver={store.resolver} email={store.email} />
                    </>
                );
            case Form.Phone:
                return (
                    <>
                        <div className={authorizationStyle.backToSignInButton} onClick={backToSignInButton}>
                            ← Back to Sign In
                        </div>
                        <PhoneForm
                            goToForgotPwd={() => dispatchLocal({ type: "setTypeOfForm", payload: Form.ForgotPwd })}
                        />
                    </>
                );
            case Form.MFA:
                return (
                    <>
                        <div className={authorizationStyle.backToSignInButton} onClick={backToSignInButton}>
                            ← Back to Sign In
                        </div>
                        <MFA
                            setTOTPMethod={() => dispatchLocal({ type: "setTypeOfForm", payload: Form.QRCode })}
                            setPhoneNumberMethod={() => dispatchLocal({ type: "setTypeOfForm", payload: Form.Phone })}
                        />
                    </>
                );
            case Form.QRCode:
                return (
                    <>
                        <div className={authorizationStyle.backToSignInButton} onClick={backToSignInButton}>
                            ← Back to Sign In
                        </div>
                        <QRCode backToSignInButton={backToSignInButton} email={store.email} />
                    </>
                );
            case Form.SuccessSentPWD:
                return (
                    <>
                        <div className={authorizationStyle.backToSignInButton} onClick={backToSignInButton}>
                            ← Back to Sign In
                        </div>
                        <SuccessSentReset email={store.email} />
                    </>
                );
            case Form.SuccessResetPWD:
                return (
                    <>
                        <div className={authorizationStyle.backToSignInButton} onClick={backToSignInButton}>
                            ← Back to Sign In
                        </div>
                        <SuccessReset email={store.email} />
                    </>
                );
            case Form.ExpiredPwd:
                return (
                    <>
                        <div className={authorizationStyle.backToSignInButton} onClick={backToSignInButton}>
                            ← Back to Sign In
                        </div>
                        <ExpiredPassword />
                    </>
                );
            case Form.UserInvitation:
                return (
                    <>
                        <div className={authorizationStyle.backToSignInButton} onClick={backToSignInButton}>
                            ← Back to Sign In
                        </div>
                        <SignUpInvitation
                            email={store.email}
                            goToSignup={() => dispatchLocal({ type: "setTypeOfForm", payload: Form.SignUp })}
                        />
                    </>
                );
            case Form.ErrorLink:
                return (
                    <>
                        <div className={authorizationStyle.backToSignInButton} onClick={backToSignInButton}>
                            ← Back to Sign In
                        </div>
                        <ResetError />
                    </>
                );
            case Form.VerifyEmail:
                return (
                    <>
                        <div className={authorizationStyle.backToSignInButton} onClick={backToSignInButton}>
                            ← Back to Sign In
                        </div>
                        <VerifyEmail email={account.name} />
                    </>
                );
            case Form.SignUp:
                return (
                    <>
                        <div className={authorizationStyle.backToSignInButton} onClick={backToSignInButton}>
                            ← Back to Sign In
                        </div>
                        <SignUpForm email={store.email} goToSignIn={acceptSignUp} />
                    </>
                );
            default:
                return <div />;
        }
    };

    return (
        <>
            {content(store.typeOfForm)}
            <If condition={store.isPending}>
                <Pending />
            </If>
        </>
    );
};

export default Authorization;
