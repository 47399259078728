import React, { useState } from "react";
import { Field, Formik } from "formik";

import { CustomInput } from "@screens/Landing/Authorization/Input";
import * as authorizationStyle from "@screens/Landing/Authorization/style.scss";
import * as buttonStyle from "@components/Button/style.scss";
import { passwordRegex } from "@common/checkValidPWD";

const ResetCredentials = (props: {
    actionCode: string;
    setSuccess: (email: string) => void;
    setError: () => void;
    email: string;
}) => {
    const [isError, setIsError] = useState(false);
    const [isProcess, setIsProcess] = useState(false);

    return (
        <div className={authorizationStyle.formCenter}>
            <div className={authorizationStyle.signFormContainer}>
                <div className={authorizationStyle.titleContainer}>
                    <div className={authorizationStyle.title}>Password reset</div>
                    <div className={authorizationStyle.text}>
                        You are currently resetting password for <span>{props.email}</span>
                    </div>
                </div>
                <Formik
                    initialValues={{
                        password: "",
                        confirm_password: "",
                    }}
                    validate={(values) => {
                        const errors: Record<string, string> = {};
                        const { password, confirm_password } = values;
                        const { weakPassword, invalidEmptyPassword, invalidPasswordMatch } = window.locales;
                        if (!password) errors.password = invalidEmptyPassword;
                        if (!confirm_password) errors.confirm_password = invalidEmptyPassword;
                        if (confirm_password !== password) {
                            errors.confirm_password = invalidPasswordMatch;
                        }
                        if (!passwordRegex.test(password)) {
                            errors.password = window.locales.weakPassword;
                        }

                        setIsError(false);
                        return errors;
                    }}
                    onSubmit={async (values, { setErrors }) => {
                        setIsProcess(true);

                        const auth = window.fb.default.auth();

                        try {
                            const { data } = await auth.checkActionCode(props.actionCode);
                            if (data.email && data.email !== values.password) {
                                await auth.confirmPasswordReset(props.actionCode, values.password);
                                props.setSuccess(data.email);
                            } else {
                                setErrors({
                                    password: window.locales.oldPassword,
                                    confirm_password: window.locales.oldPassword,
                                });
                                setIsError(true);
                            }
                        } catch (e: any) {
                            props.setError();
                        }
                        setIsProcess(false);
                    }}
                >
                    {({ values, handleSubmit }) => (
                        <form onSubmit={handleSubmit} className={authorizationStyle.formContainer}>
                            <div className={authorizationStyle.fieldContainer}>
                                <Field
                                    component={CustomInput}
                                    value={values.password}
                                    type="password"
                                    name="password"
                                    placeholder="New password"
                                />
                            </div>
                            <div className={authorizationStyle.fieldContainer}>
                                <Field
                                    component={CustomInput}
                                    value={values.confirm_password}
                                    type="password"
                                    name="confirm_password"
                                    placeholder="Confirm Password"
                                />
                            </div>
                            {isError && (
                                <div className={authorizationStyle.errorContainer}>
                                    {window.locales.invalidEmailMatchPassword}
                                </div>
                            )}
                            <div className={authorizationStyle.buttonContainer}>
                                <button
                                    className={buttonStyle.buttonSubmit}
                                    disabled={isError || isProcess}
                                    type="submit"
                                >
                                    Reset
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export { ResetCredentials };
