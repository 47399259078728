import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";

import successIcon from "@common/img/svg/success-icon.svg";
import { successInfo } from "@state/success";
import { errorInfo } from "@state/error";

import * as styles from "./styles.scss";

const SuccessNotification = observer(() => {
    useEffect(() => {
        if (successInfo.isSuccess) {
            setTimeout(() => {
                successInfo.closeNotification();
            }, 10000);
            setTimeout(() => {
                successInfo.resetSuccessInfo();
            }, 12000);
        }
    }, [successInfo.isSuccess]);

    return (
        <div
            className={`${styles.successNotificationContainer} ${(errorInfo.isError || !successInfo.isSuccess) && styles.hide}`}
        >
            <div className={styles.successNotification}>
                <div className={styles.iconContainer}>
                    <img src={successIcon} alt="success" />
                </div>
                <div className={styles.textContainer}>{successInfo.description || window.locales.successChanges}</div>
            </div>
        </div>
    );
});

export default SuccessNotification;
