import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import RootState from "@state/interfaces";
import { ProjectType } from "@screens/Projects/Projects.types";
import { Search } from "@components/Search/Search";
import { ProjectIcon } from "@components/IconProject";

import * as style from "./style.scss";

const regex = new RegExp(/^[a-zA-Z\d_-]*$/i);

const ProjectsSelector = () => {
    const projects = useSelector((state: RootState) => state.projects);

    const [filteredList, setFilteredList] = useState<ProjectType[]>([]);
    const [searchValue, setSearchValue] = useState("");
    const filteredData: any = useRef();

    useEffect(() => {
        const isValidValue = regex.test(searchValue) && searchValue !== "";
        const parseProjects = Object.entries(projects).map((project) => project[1]);
        const lowerSearchValue = searchValue.toLowerCase().trim();
        filteredData.current = parseProjects;
        if (lowerSearchValue && isValidValue) {
            const filtered = filteredData.current.filter(
                ({ carrier_name = "", publickey = "" }: ProjectType) =>
                    carrier_name.toLowerCase().includes(lowerSearchValue) ||
                    publickey.toLowerCase().includes(searchValue),
            );

            setFilteredList(filtered);
        } else {
            setFilteredList(filteredData.current);
        }
    }, [projects, searchValue]);

    const searchChange = (value: string) => {
        setSearchValue(value);
    };

    return (
        <div className={style.projectsSelectorContainer}>
            <Search callback={searchChange} />
            <div>
                {filteredList.map((project, index) => (
                    <NavLink
                        to={project.publickey}
                        key={index}
                        className={({ isActive }) => `${style.customLink} ${isActive && style.selectedLink}`}
                    >
                        <div className={style.projectContainer}>
                            <ProjectIcon icon={project.icon} carrier_name={project.publickey} />
                            <div className={style.projectName}>
                                {project.carrier_name}
                                <div className={style.projectKey}>{project.publickey}</div>
                            </div>
                        </div>
                    </NavLink>
                ))}
            </div>
        </div>
    );
};

export { ProjectsSelector };
