import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "@common/style/global.style.scss";
import "./dynamic-scss-variables";
import firebase from "firebase/compat";

import Landing from "@screens/Landing";
import { deleteAccount, getAccount, setAccount } from "@state/account";
import { EAuthorizationStatus, ECode, IErrorResponse } from "@interfaces";
import { getParameterByName } from "@common/getParameterByName";
import { Form } from "@screens/Landing/Authorization/types";
import { getLatestUpdatePwd } from "@common/getLatestUpdatePwd";
import { isExpiredDate } from "@common/checkExpiredDate/isExpiredDate";
import { AuthContext } from "@common/auth-context";
import { requestGetToken, requestRefreshToken } from "@services/Authorization";
import { getTokenInStorage, setTokenInStorage, TokenType } from "@common/tokenStorage";
import { getInfoFromFirebase } from "@services/firebase/InitApp";
import OktaSignIn from "@screens/Landing/Authorization/OktaSignIn";
import { errorInfo } from "@state/error";
import ErrorNotification from "@components/ErrorNotification";
import SuccessNotification from "@components/SuccessNotification";
import whiteList from "../2fa-avoid-list.json";

import Portal from "./Portal";
import { successInfo } from "@state/success";

const App = () => {
    const [alreadySent, setAlreadySent] = useState(false);
    const dispatch = useDispatch();
    const [authorizationStatus, setAuthorizationStatus] = useState<EAuthorizationStatus>(EAuthorizationStatus.Pending);
    const [initPage, setInitPage] = useState<Form>(Form.SignIn);

    useEffect(() => {
        if (window.location.href === "https://developer.aura.com/") {
            window.location.replace("https://www.pango-cloud.com/");
        }
    }, []);

    const setStoreInStorage = useCallback(
        (access_token: string, user_email: string) =>
            dispatch(
                setAccount({
                    verified: true,
                    authorized: true,
                    isOkta: true,
                    firebaseToken: access_token,
                    name: user_email,
                }),
            ),
        [dispatch],
    );

    const setTokenObserver = useCallback(
        ({ access_token, refresh_token, expiry, token_type, user_email }: TokenType) => {
            setStoreInStorage(access_token, user_email);
            setTokenInStorage({
                access_token,
                refresh_token,
                expiry,
                token_type,
                user_email,
            });
            window.setTimeout(
                async () => {
                    const {
                        data: { token },
                    } = await requestRefreshToken(refresh_token);
                    setTokenObserver(token);
                },
                expiry * 1000 - 300000,
            );
        },
        [setStoreInStorage],
    );

    useEffect(() => {
        const auth = window.fb.default.auth() as firebase.auth.Auth;
        auth.onAuthStateChanged(async (user: firebase.User | null) => {
            const actionCodeOktaError = getParameterByName(ECode.Error);
            const actionCodeOktaErrorDescription = getParameterByName(ECode.ErrorDescription);
            if (actionCodeOktaError) {
                errorInfo.setErrorInfo({
                    title: actionCodeOktaError,
                    description: actionCodeOktaErrorDescription,
                });

                setTimeout(() => window.location.replace(window.location.origin), 6000);
                return;
            }

            const tokenStorage = getTokenInStorage();
            try {
                if (tokenStorage) {
                    const { refresh_token } = tokenStorage;
                    const {
                        data: { token },
                    } = await requestRefreshToken(refresh_token);
                    setTokenObserver(token);
                    setAuthorizationStatus(EAuthorizationStatus.Authorized);
                    return;
                }
            } catch (e: unknown | IErrorResponse) {
                const error = e as IErrorResponse;
                errorInfo.setErrorInfo({
                    title: error.code,
                    description: error.message,
                });

                dispatch(deleteAccount());
                setAuthorizationStatus(EAuthorizationStatus.Unauthorized);
            }

            const actionCodeReset = getParameterByName(ECode.ActionCode);
            const actionCodeOkta = getParameterByName(ECode.Code);
            const actionCodeOktaState = getParameterByName(ECode.State);
            if (!actionCodeReset && actionCodeOkta && actionCodeOktaState) {
                try {
                    const {
                        data: { token },
                    } = await requestGetToken(actionCodeOkta, actionCodeOktaState);
                    setTokenObserver(token);
                    setAuthorizationStatus(EAuthorizationStatus.Authorized);
                } catch (e: unknown | IErrorResponse) {
                    const error = e as IErrorResponse;
                    errorInfo.setErrorInfo({
                        title: error.code,
                        description: error.message,
                    });

                    dispatch(deleteAccount());
                    setAuthorizationStatus(EAuthorizationStatus.Unauthorized);
                    setTimeout(() => window.location.replace(window.location.origin), 6000);
                }

                return;
            }

            if (user && !actionCodeReset) {
                const firebaseToken = await user.getIdToken();
                const latestUpdatePwd = await getLatestUpdatePwd(firebaseToken);
                if (!user.emailVerified && !alreadySent) {
                    await user.sendEmailVerification();
                    setInitPage(Form.VerifyEmail);
                    setAuthorizationStatus(EAuthorizationStatus.Unauthorized);
                    successInfo.setSuccessInfoWithText(window.locales.invitationAccepted);
                    setAlreadySent(true);
                    return;
                }

                if (isExpiredDate(latestUpdatePwd)) {
                    setInitPage(Form.ExpiredPwd);
                    setAuthorizationStatus(EAuthorizationStatus.Unauthorized);
                    return;
                }

                const { uid, phoneNumber, email = "", emailVerified } = user;
                const [isMFA] = user.multiFactor.enrolledFactors || [];

                if (!isMFA && email && !whiteList.includes(email)) {
                    setInitPage(Form.MFA);
                    setAuthorizationStatus(EAuthorizationStatus.Unauthorized);
                    return;
                }

                const infoFromFirebase = await getInfoFromFirebase(uid);
                dispatch(
                    setAccount({
                        uid,
                        authorized: true,
                        phoneNumber,
                        name: email,
                        verified: emailVerified,
                        firebaseToken,
                        isOkta: false,
                        infoFromFirebase,
                    }),
                );

                setInterval(() => dispatch(getAccount()), 3000000);
                setAuthorizationStatus(EAuthorizationStatus.Authorized);
            } else if (!user) {
                setInitPage(Form.SignIn);
                setAuthorizationStatus(EAuthorizationStatus.Unauthorized);
            } else if (user && actionCodeReset) {
                try {
                    const { data } = await auth.checkActionCode(actionCodeReset);
                    if (data.email !== user.email) {
                        dispatch(deleteAccount());
                        setInitPage(Form.SignIn);
                        setAuthorizationStatus(EAuthorizationStatus.Unauthorized);
                        return;
                    }

                    setAuthorizationStatus(EAuthorizationStatus.Authorized);
                } catch {
                    setInitPage(Form.ErrorLink);
                    setAuthorizationStatus(EAuthorizationStatus.Unauthorized);
                }
            } else {
                setAuthorizationStatus(EAuthorizationStatus.Unauthorized);
            }
        });
    }, [dispatch, setTokenObserver]);

    return (
        <AuthContext.Provider value={[authorizationStatus, setAuthorizationStatus]}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Landing initPage={initPage} />} />
                    <Route path="/auth/callback" element={<OktaSignIn />} />
                    <Route path="/*" element={<Portal />} />
                </Routes>
                <ErrorNotification />
                <SuccessNotification />
            </BrowserRouter>
        </AuthContext.Provider>
    );
};

export { App };
