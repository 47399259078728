import React, { useContext, useState } from "react";
import { Field, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import * as authorizationStyle from "@screens/Landing/Authorization/style.scss";
import { CustomInput } from "@screens/Landing/Authorization/Input";
import * as buttonStyle from "@components/Button/style.scss";
import { RootState } from "@state/index";
import { AuthContext } from "@common/auth-context";
import { EAuthorizationStatus } from "@interfaces";
import { deleteAccount } from "@state/account";
import { passwordRegex } from "@common/checkValidPWD";

const ExpiredPassword = () => {
    const dispatch = useDispatch();
    const account = useSelector((state: RootState) => state.account);
    const [isProcess, setIsProcess] = useState(false);
    const [, setAuthorizationStatus] = useContext(AuthContext);

    return (
        <div className={authorizationStyle.formCenter}>
            <div className={authorizationStyle.signFormContainer}>
                <div className={authorizationStyle.titleContainer}>
                    <div className={authorizationStyle.title}>Change password</div>
                    <div className={authorizationStyle.text}>
                        Password for <span>{account.name}</span> has expired. Change it to log in.
                    </div>
                </div>
                <Formik
                    initialValues={{
                        new_password: "",
                        confirm_password: "",
                    }}
                    validate={(values) => {
                        const errors: Record<string, string> = {};
                        const { confirm_password, new_password } = values;
                        const { invalidEmptyPassword, invalidPasswordMatch } = window.locales;
                        if (new_password !== confirm_password) {
                            errors.confirm_password = invalidPasswordMatch;
                        }
                        if (!passwordRegex.test(new_password)) {
                            errors.new_password = window.locales.weakPassword;
                        }

                        if (!confirm_password) errors.confirm_password = invalidEmptyPassword;
                        if (!new_password) errors.new_password = invalidEmptyPassword;

                        return errors;
                    }}
                    onSubmit={async (values, { setErrors }) => {
                        setIsProcess(true);
                        const { new_password } = values;

                        try {
                            await window.fb.default.auth().currentUser.updatePassword(new_password);
                            setAuthorizationStatus(EAuthorizationStatus.Authorized);
                        } catch (e: any) {
                            if (e.code === "auth/requires-recent-login") {
                                setErrors({
                                    new_password: window.locales.reLogin,
                                });

                                setTimeout(() => dispatch(deleteAccount()), 3000);

                                return;
                            }

                            setErrors({
                                new_password: e.message,
                                confirm_password: e.message,
                            });
                        }

                        setIsProcess(false);
                    }}
                >
                    {({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit} className={authorizationStyle.formContainer}>
                            <div className={authorizationStyle.fieldContainer}>
                                <Field
                                    component={CustomInput}
                                    value={values.new_password}
                                    type="password"
                                    name="new_password"
                                    placeholder="New password"
                                />
                            </div>
                            <div className={authorizationStyle.fieldContainer}>
                                <Field
                                    component={CustomInput}
                                    value={values.confirm_password}
                                    type="password"
                                    name="confirm_password"
                                    placeholder="Confirm Password"
                                />
                            </div>
                            <div className={authorizationStyle.buttonContainer}>
                                <button className={buttonStyle.buttonSubmit} disabled={isProcess} type="submit">
                                    Confirm
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export { ExpiredPassword };
